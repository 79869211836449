@import '~@fortawesome/fontawesome-free/css/all.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: unset !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  margin: 0;
  padding: 0;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

div {
  box-sizing: border-box;
}

#root {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.flex1 {
  flex: 1;
}

.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
  display: flex;
}

.pac-container {
  z-index: 1400 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.justify-content-center {
  justify-content: center;
}

.text-align-center {
  text-align: center;
}

.w-100 {
  width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.cursor-unset {
  cursor: unset;
}

#mobile-number-field::placeholder {
  color: #cabde9;
  opacity: 1;
}

.purple_placeholder::placeholder {
  color: #cabde9;
  opacity: 1;
}

.dashed-line {
  margin-top: 5px;
  height: 2px;
  background: repeating-linear-gradient(90deg, #b29de2 0 10px, #0000 0 15px);
}

.purple_scroll::-webkit-scrollbar {
  background: #e9e9e9;
  width: 8px;
}

/* Track */
.purple_scroll::-webkit-scrollbar-track {
  width: 4px;
  background: #e9e9e9;
  border-radius: 60px;
}

/* Handle */
.purple_scroll::-webkit-scrollbar-thumb {
  background: #8d027d;
  border-radius: 60px;
}

.global_header_text {
  font-size: 20px;
  color: #1e1e1e;
  line-height: 36px;
}